import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Table from "../views/Table.vue";
import DeviceDetail from "../views/DeviceDetail.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		redirect: "/devices"
	},
	{
		path: "/devices",
		component: Table,
		props: {
			dataset: "devices",
			heading: "Jednotky"
		}
	},
	{
		path: "/orgs",
		component: Table,
		props: {
			dataset: "orgs",
			heading: "Organizace"
		}
	},
	{
		path: "/users",
		name: "users",
		component: Table,
		props: {
			dataset: "users",
			heading: "Uživatelské účty"
		}
	},
	{
		path: "/device/:gid",
		name: "device",
		component: DeviceDetail,
		props (route) {
			return {
				gid: Number(route.params.gid)
			}
		}
	},
	{
		path: "/login",
		component: Login,
		meta: {
			public: true
		}
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((destination, source, next) => {
	if (!(Boolean(window.token) || destination.meta.public)) {
		next({
			path: "/login",
			query: {
				redirect: destination.fullPath
			}
		});
	} else {
		next();
	}
});

export default router;
