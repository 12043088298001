var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":_vm.dataset == 'devices'}},[_c('v-row',[_c('v-col',[_c('h1',{staticClass:"subheading text--disabled"},[_vm._v(_vm._s(_vm.heading))])])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",on:{"click":_vm.export_visible}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-table")]),_c('span',[_vm._v("Stáhnout tabulku")])],1)]}}])},[_c('span',[_vm._v("Stáhnutí vyfiltrovaných položek tabulky ve formátu CSV.")])]),(_vm.dataset == 'devices')?_c('new-device'):_vm._e(),(_vm.dataset == 'orgs')?_c('edit-new-org',{attrs:{"btn-text":"Nová organizace","btn-icon":"mdi-plus"}}):_vm._e(),(_vm.dataset == 'users')?_c('edit-new-user',{attrs:{"btn-text":"Nový uživatelský účet","btn-icon":"mdi-plus"}}):_vm._e()],1)],1),_c('v-row',[(_vm.dataset == 'devices')?_c('v-col',[_c('v-data-table',{ref:"tbl",attrs:{"headers":_vm.header_dev,"items":_vm.$root.devices,"items-per-page":15,"search":_vm.search,"custom-filter":_vm.filter_dev,"sort-by":"mfr_timestamp","sort-desc":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Vyhledat","prepend-icon":"mdi-magnify","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.serial",fn:function(ref){
var item = ref.item;
return [_c('serial-number',{attrs:{"number":item.serial}})]}},{key:"item.model",fn:function(ref){
var item = ref.item;
return [_c('model-number',{attrs:{"number":item.model.mpn,"ext":item.model_ext}})]}},{key:"item.mfr_timestamp",fn:function(ref){
var item = ref.item;
return [_c('cz-date',{attrs:{"timestamp":item.mfr_timestamp}})]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('maybe-shipped',{attrs:{"device":item}})]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return [_c('table-tags',{attrs:{"tags":item.tags}})]}},{key:"item.gid",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"text--disabled",attrs:{"elevation":"0","icon":"","href":'https://grafana.wafe.eu/d/_v5lhgPWz/detail-jednotky-ace?orgId=1&var-serial=' + item.serial,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-chart-line")])],1),_c('v-btn',{attrs:{"elevation":"0","icon":"","to":{name: 'device', params: {gid: item.gid}}}},[_c('v-icon',[_vm._v("mdi-arrow-right")])],1)]}}],null,false,950372261)})],1):_vm._e(),(_vm.dataset == 'orgs')?_c('v-col',[_c('v-data-table',{ref:"tbl",attrs:{"headers":_vm.header_org,"items":_vm.$root.orgs,"items-per-page":15,"search":_vm.search,"custom-filter":_vm.filter_org,"sort-by":"gid","sort-desc":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Vyhledat","prepend-icon":"mdi-magnify","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),(item.mgmt)?_c('v-icon',[_vm._v("mdi-shield-star-outline")]):_vm._e()]}},{key:"item.links",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/devices?search=" + (item.name))}},[_vm._v("Jednotky")]),_vm._v(" | "),_c('a',{attrs:{"target":"_blank","href":("https://grafana.wafe.eu/d/CsQIoPsWz/seznam-jednotek-ace-podle-organizace?orgId=1&var-org=" + (item.name))}},[_vm._v(" Grafana "),_c('v-icon',{attrs:{"small":"","color":"grey"}},[_vm._v("mdi-open-in-new")])],1)]}},{key:"item.gid",fn:function(ref){
var item = ref.item;
return [_c('edit-new-org',{attrs:{"org":item,"btn-icon":"mdi-home-edit-outline"}})]}}],null,false,1897804710)})],1):_vm._e(),(_vm.dataset == 'users')?_c('v-col',[_c('v-data-table',{ref:"tbl",attrs:{"headers":_vm.header_usr,"items":_vm.$root.users,"items-per-page":15,"search":_vm.search,"custom-filter":_vm.filter_usr,"sort-by":"gid","sort-desc":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Vyhledat","prepend-icon":"mdi-magnify","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.login",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.login)+" "),(item.iceberg_admin)?_c('v-icon',[_vm._v("mdi-shield-star-outline")]):_vm._e()]}},{key:"item.gid",fn:function(ref){
var item = ref.item;
return [_c('edit-new-user',{attrs:{"user":item,"btn-icon":"mdi-account-edit-outline"}})]}}],null,false,3668713894)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }