<template>
	<v-row>
		<v-col cols="4" align="right">
			{{ tag }}
		</v-col>
		<v-col>
			<span>{{ device.tags[tag] || '—' }}</span>
			<span class="float-right">
				<v-btn v-if="!protected" x-small text @click="remove">
					Smazat
				</v-btn>
				<v-dialog v-model="dialog" :persistent="!save_disabled" max-width="512">
					<template v-slot:activator="{ on, attrs }">
						<v-btn x-small text v-bind="attrs" v-on="on">
							Změnit
						</v-btn>
					</template>
					<v-card>
						<v-card-text>
							<v-container>
								<v-row>
									<v-col>
										<v-text-field label="Název" v-model="tag_name" @keydown.enter="$refs.val.focus()" autofocus :disabled="protected"></v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-text-field ref="val" label="Hodnota" v-model="tag_value" @keydown.enter="save" :clearable="protected"></v-text-field>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
						<v-card-actions class="pa-4">
							<v-spacer></v-spacer>
							<v-btn text @click="dialog = false" :disabled="save_loading">Zrušit</v-btn>
							<v-btn :disabled="save_disabled || save_loading" color="primary" @click="save" :loading="save_loading">Uložit</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</span>
		</v-col>
	</v-row>
</template>

<script>
import misc from "@/misc";

export default {
	props: {
		device: {
			type: Object,
			required: true
		},
		tag: {
			type: String,
			required: true
		}
	},
	data: function () {
		return {
			save_loading: false,
			tag_name: null,
			tag_value: null,
			dialog_visible: false
		}
	},
	computed: {
		save_disabled: function () {
			return (this.tag_name == this.tag) && (this.tag_value == this.device.tags[this.tag]);
		},
		dialog: {
			get () {
				return this.dialog_visible;
			},
			set (val) {
				this.reset();
				this.dialog_visible = val;
			}
		},
		protected: function () {
			return this.tag == misc.protected_tag_name;
		}
	},
	methods: {
		reset: function () {
			this.save_loading = false;
			this.tag_name = this.tag;
			this.tag_value = this.device.tags[this.tag];
		},
		remove: function () {
			this.$delete(this.device.tags, this.tag);
			this.sync_state();
		},
		save: function () {
			this.save_loading = true;
			
			this.$delete(this.device.tags, this.tag);
			this.$set(this.device.tags, this.tag_name, this.tag_value);
			
			let inst = this;
			
			this.sync_state(function (response) {
				inst.save_loading = true;
				inst.dialog = false;
			});
		},
		sync_state: function (cb) {
			// serialize them right here
			let parts = [this.device.tags[misc.protected_tag_name]];
			
			Object.entries(this.device.tags).sort().forEach((item) => {
				let key = item[0];
				let val = item[1];
				
				if (key == misc.protected_tag_name) {
					return;
				}
				
				parts.push(`$${key}=${val}`);
			});
			
			this.device.note = parts.join(" ");
			
			this.$root.api_call_put_object("/device", this.device, cb);
		}
	}
};
</script>
