<template>
	<v-dialog v-model="dialog" :persistent="save_required" max-width="512">
		<template v-slot:activator="{ on, attrs }">
			<v-btn color="primary" v-bind="attrs" v-on="on">
				<v-icon left>mdi-plus</v-icon>
				<span>Nová výroba</span>
			</v-btn>
		</template>
		
		<v-card>
			<v-form v-model="valid">
				<v-card-title>
					<span class="headline">Registrace nových jednotek</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col>
								<p>Vyberte typ a počet. Backstage jednotky zaregistruje, přidělí jim čísla, označí je všechny stejným štítkem a nakonec je zobrazí v tabulce.</p>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-text-field label="Počet" type="number" v-model="amount" :rules="[is_amount]" />
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-autocomplete
									:items="mpns_sorted"
									item-text="mpn"
									return-object
									label="Model (MPN)"
									v-model="mpn"
									:rules="[not_null]"
								/>
							</v-col>
							<v-col cols=4>
								<v-text-field label="Za pomlčkou" v-model="ext" />
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-text-field label="Poznámka" v-model="note" clearable />
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-text-field label="Označení série" v-model="series" />
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions class="pa-4">
					<model-number v-if="mpn" :number="mpn.mpn" activator="Dekódovat MPN" />
					<v-spacer></v-spacer>
					<v-btn text @click="dialog = false" :disabled="loading">Zrušit</v-btn>
					<v-btn :disabled="!valid || !save_required || loading" color="primary" @click="save" :loading="loading">Vytvořit</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
import ModelNumber from "@/components/ModelNumber";

export default {
	components: {
		ModelNumber
	},
	props: {
		
	},
	data: function () {
		return {
			valid: false,
			loading: false,
			mpn: null,
			ext: null,
			note: "",
			series: "",
			amount: 1,
			dialog_: false
		}
	},
	computed: {
		save_required: function () {
			return Boolean(this.mpn);
		},
		dialog: {
			get () {
				return this.dialog_;
			},
			set (val) {
				this.reset();
				this.dialog_ = val;
			}
		},
		mpns_sorted: function () {
			let copy = this.$root.models.slice();
			
			copy.sort(function (a, b) {
				let a_name = a.mpn.toLowerCase();
				let b_name = b.mpn.toLowerCase();
				
				if (a_name < b_name) return -1;
				else if (a_name > b_name) return 1;
				else return 0;
			});
			
			return copy;
		}
	},
	methods: {
		reset: function () {
			this.loading = false;
			this.mpn = null;
			this.note = "";
			
			let dt = new Date();
			let Y = dt.getFullYear();
			let m = (dt.getMonth() + 1).toString().padStart(2, "0");
			let d = (dt.getDate()).toString().padStart(2, "0");
			this.series = `${Y}-${m}-${d}`;
		},
		save: function () {
			if (!this.valid) {
				return;
			}
			
			this.loading = true;
			
			let inst = this;
			let obj = {
				model: this.mpn,
				model_ext: (this.ext? this.ext.toUpperCase() : null),
				note: this.note,
				series: this.series,
				amount: this.amount
			}
			
			this.$root.api_call_post_object("/devices-new", obj, function (response) {
				inst.$root.refresh_global_state();
				inst.$router.push(`/devices?search=$Série=${inst.series}`);
				inst.dialog = false;
			},
			function () {
				// on failure
			})
		},
		not_null: function (val) {
			if (!val) {
				return "Bez MPN to nepůjde.";
			}
			
			return true;
		},
		is_amount: function (val) {
			if (!val || val < 1) {
				return "Aspoň jednu.";
			}
			
			return true;
		}
	}
};
</script>

<style scoped>
.bs-alert {
	opacity: 0.0;
	transition: opacity 0.5s;
}

.bs-alert-visible {
	opacity: 1.0;
}
</style>