<template>
	<span>
		<v-dialog v-model="dialog" width="320">
			<v-card>
				<v-card-title class="decoded">
					<span class="year">{{ year }}</span>
					<span class="month">{{ month }}</span>
					<span class="count">{{ count }}</span>
					<span class="rand">{{ rand }}</span>
				</v-card-title>
				<v-card-text class="decoded">
					<p>
						<span class="count">{{ Number(count) }}</span>. jednotka vyrobená v <span class="month">{{ month_cz }}</span> 20<span class="year">{{ year }}</span>.
					</p>
					<p>
						Poslední šestičíslí (<span class="rand">{{ rand }}</span>) nenese žádnou informaci.
					</p>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary" text @click="dialog = false">Zavřít</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<span @click="dialog = true" class="compact">
			<span class="year">{{ year }}</span>
			<span class="month">{{ month }}</span>
			<span class="count">{{ count }}</span>
			<span class="rand">{{ rand }}</span>
		</span>
	</span>
</template>

<script>
const months = [
	"lednu",
	"únoru",
	"březnu",
	"dubnu",
	"květnu",
	"červnu",
	"červenci",
	"srpnu",
	"září",
	"říjnu",
	"listopadu",
	"prosinci"
];

export default {
	props: {
		number: {
			type: String,
			required: true,
			validator: function (value) {
				return value.length == 13;
			}
		}
	},
	data: function () {
		return {
			dialog: false
		}
	},
	computed: {
		year: function () {
			return this.number.slice(0, 2);
		},
		month: function () {
			return this.number.slice(2, 4);
		},
		month_cz: function () {
			return months[Number(this.month) - 1];
		},
		count: function () {
			return this.number.slice(4, 7);
		},
		rand: function () {
			return this.number.slice(7, 13);
		},
	}
};
</script>

<style lang="scss" scoped>
.compact {
	cursor: pointer;
	
	.rand {
		opacity: 0.5;
		font-size: 100%;
	}
	
	&:hover {
		text-decoration: underline;
	}
}

.decoded {
	.year {
		color: #f44;
	}
	
	.month {
		color: #4b4;
	}
	
	.count {
		color: #b84;
	}
	
	.rand {
		opacity: 0.5;
	}
}

</style>