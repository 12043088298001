<template>
	<span>
		<v-chip v-if="tag_main" small outlined class="ma-1">{{ tag_main }}</v-chip>
		<v-chip v-for="tag in tags_rest" :key="tag.name" :color="tag.color" small outlined class="ma-1" :title="tag.name">{{ tag.text }}</v-chip>
	</span>
</template>

<script>
import misc from "@/misc";

export default {
	props: {
		tags: {
			type: Object,
			required: true
		}
	},
	data: function () {
		return {
			
		}
	},
	computed: {
		tag_main: function () {
			return this.tags[misc.protected_tag_name];
		},
		tags_rest: function () {
			let rest = [];
			
			Object.entries(this.tags).sort().forEach((item) => {
				let key = item[0];
				let val = item[1];
				
				if (key == misc.protected_tag_name) {
					return;
				}
				
				let key_short = this.$root.tags_abbr[key];
				
				rest.push({
					text: `${key_short}:${val}`,
					color: misc.word_to_color(key_short, 0x3ff, 0xbff),
					name: key
				});
			});
			
			return rest;
		}
	}
};
</script>
