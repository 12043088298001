<template>
	<span>
		{{ state_cz }}
	</span>
</template>

<script>
import misc from "@/misc";

function days_cz (n) {
	if (n == 1) {
		return "1 den";
	} else if (n < 5) {
		return `${n} dny`;
	} else {
		return `${n} dní`;
	}
}

export default {
	props: {
		device: {
			type: Object,
			required: true
		}
	},
	data: function () {
		return {
			
		}
	},
	computed: {
		state_cz: function () {
			if (this.device.exw_timestamp) {
				return misc.timestamp_to_cz_datetime(this.device.exw_timestamp);
			} else if (this.device.mfr_timestamp) {
				let days = ((Date.now() / 1000 - this.device.mfr_timestamp) / 86400) | 0;
				
				if (days < 2) {
					return "Na skladě";
				} else {
					return "Na skladě (" + days_cz(days) + ")";
				}
			}
		}
	}
};
</script>
