<template>
	<nav>
		<v-app-bar flat app>
			<v-app-bar-nav-icon class="d-inline-flex d-lg-none" v-if="show_nav" @click="drawer = !drawer"></v-app-bar-nav-icon>
			
			<v-toolbar-title class="text-uppercase">
				<span class="font-weight-light text--disabled">WAFE</span>
				<span class="font-weight-medium text--disabled">Backstage</span>
			</v-toolbar-title>
			
			<div v-if="show_nav" class="d-none d-lg-inline ml-12">
				<v-btn text class="mx-2" v-for="link in links" :key="link.text" router :to="link.route">
					<span>{{ link.text }}</span>
				</v-btn>
			</div>
			
			<v-spacer></v-spacer>
			
			<v-btn v-if="show_nav" text class="text--disabled" @click="reload_state">
				<span class="d-none d-sm-inline">Obnovit</span>
				<v-icon right>mdi-reload</v-icon>
			</v-btn>
			
			<v-btn v-if="show_nav" text class="d-none d-lg-inline text--disabled" @click="logout">
				<span class="d-none d-sm-inline">Odhlásit se</span>
				<v-icon right>mdi-logout</v-icon>
			</v-btn>
			
			<v-btn icon @click="$vuetify.theme.dark = !$vuetify.theme.dark" class="text--disabled">
				<v-icon>{{ theme_toggle_icon }}</v-icon>
			</v-btn>
		</v-app-bar>
		
		<v-navigation-drawer v-if="show_nav" v-model="drawer" app bottom temporary>
			<!--v-list-item>
				<v-list-item-content>
					<v-list-item-title>{{ $root.identity.display_name }}</v-list-item-title>
					<v-list-item-subtitle>#{{ $root.identity.gid }}</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			
			<v-divider></v-divider-->
			
			<v-list nav>
				 <v-list-item-group v-model="selected_link" color="primary">
					<v-list-item v-for="link in links" :key="link.text" router :to="link.route">
						<v-list-item-icon>
							<v-icon>{{ link.icon }}</v-icon>
						</v-list-item-icon>
						
						<v-list-item-content>
							<v-list-item-title>{{ link.text }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					
					<v-divider></v-divider>
					
					<v-list-item @click="logout">
						<v-list-item-icon>
							<v-icon>mdi-logout</v-icon>
						</v-list-item-icon>
						
						<v-list-item-content>
							<v-list-item-title>Odhlásit se</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>
	</nav>
</template>

<script>
export default {
	data: function () {
		return {
			drawer: false,
			selected_link: 0,
			links: [
				{ icon: "mdi-fan", text: "Jednotky", route: "/devices" },
				{ icon: "mdi-domain", text: "Organizace", route: "/orgs" },
				{ icon: "mdi-account-multiple", text: "Uživatelské účty", route: "/users" }
			]
		}
	},
	mounted () {
		if (localStorage.dark_mode == "true") {
			this.$vuetify.theme.dark = true;
		}
	},
	watch: {
		"$vuetify.theme.dark": function (val) {
			localStorage.dark_mode = val;
		}
	},
	methods: {
		logout: function () {
			this.$root.logout();
		},
		reload_state: function () {
			this.$root.refresh_global_state();
		}
	},
	computed: {
		theme_toggle_icon: function () {
			return (this.$vuetify.theme.dark)? "mdi-lightbulb-outline" : "mdi-lightbulb";
		},
		show_nav: function () {
			return !this.$route.meta.public;
		}
	}
};
</script>
