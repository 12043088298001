import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import cs from "vuetify/lib/locale/cs";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
	lang: {
		locales: {
			cs
		},
		current: "cs",
	},
	theme: {
		dark: false
	},
	breakpoint: {
		mobileBreakpoint: "sm"
	},
	karel: colors
});
