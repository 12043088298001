import { render, staticRenderFns } from "./EditNewOrg.vue?vue&type=template&id=634dd829&scoped=true&"
import script from "./EditNewOrg.vue?vue&type=script&lang=js&"
export * from "./EditNewOrg.vue?vue&type=script&lang=js&"
import style0 from "./EditNewOrg.vue?vue&type=style&index=0&id=634dd829&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "634dd829",
  null
  
)

export default component.exports