<template>
	<v-dialog v-model="dialog" :persistent="!save_disabled" max-width="512">
		<template v-slot:activator="{ on, attrs }">
			<v-btn x-small text v-bind="attrs" v-on="on" class="float-right">
				Změnit
			</v-btn>
		</template>
		<v-card>
			<v-card-title>
				<span class="headline">Změna {{ title }}</span>
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-row>
						<v-col>
							<slot></slot>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-text-field v-if="lines == 1" :label="label" v-model="value" @keydown.enter="save" clearable autofocus />
							<v-textarea v-else :rows="lines" :label="label" v-model="value" clearable autofocus />
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
			<v-card-actions class="pa-4">
				<v-spacer></v-spacer>
				<v-btn text @click="dialog = false" :disabled="save_loading">Zrušit</v-btn>
				<v-btn :disabled="save_disabled || save_loading" color="primary" @click="save" :loading="save_loading">Uložit</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		obj: {
			type: Object,
			required: true
		},
		prop: {
			type: String,
			required: true
		},
		endpoint: {
			type: String,
			required: true,
			validator: function (value) {
				return value && value[0] == "/";
			}
		},
		title: {
			type: String,
			required: true
		},
		label: {
			type: String,
			required: true
		},
		lines: {
			type: Number,
			default: 1
		}
	},
	data: function () {
		return {
			save_loading: false,
			value_original: null,
			value: null,
			dialog_visible: false
		}
	},
	computed: {
		save_disabled: function () {
			return this.value == this.value_original;
		},
		dialog: {
			get () {
				return this.dialog_visible;
			},
			set (val) {
				this.reset();
				this.dialog_visible = val;
			}
		}
	},
	methods: {
		reset: function () {
			this.save_loading = false;
			this.value_original = this.obj[this.prop];
			this.value = this.obj[this.prop];
		},
		save: function () {
			this.save_loading = true;
			this.obj[this.prop] = this.value;
			
			let inst = this;
			
			this.$root.api_call_put_object("/device", this.obj, function (response) {
				inst.save_loading = true;
				inst.dialog = false;
			},
			function () {
				inst.obj[inst.prop] = inst.value_original;
			})
		}
	}
};
</script>
