<template>
	<v-container :fluid="dataset == 'devices'">
		<v-row>
			<v-col>
				<h1 class="subheading text--disabled">{{ heading }}</h1>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="d-flex">
				<v-spacer></v-spacer>
				
				<v-tooltip left>
					<template v-slot:activator="{on, attrs}">
						<v-btn v-bind="attrs" v-on="on" class="mr-4" @click="export_visible">
							<v-icon left>mdi-file-table</v-icon>
							<span>Stáhnout tabulku</span>
						</v-btn>
					</template>
					<span>Stáhnutí vyfiltrovaných položek tabulky ve formátu CSV.</span>
				</v-tooltip>
				
				<new-device v-if="dataset == 'devices'" />
				<edit-new-org v-if="dataset == 'orgs'" btn-text="Nová organizace" btn-icon="mdi-plus" />
				<edit-new-user v-if="dataset == 'users'" btn-text="Nový uživatelský účet" btn-icon="mdi-plus" />
				
			</v-col>
		</v-row>
		<v-row>
			<v-col v-if="dataset == 'devices'">
				<v-data-table ref="tbl" :headers="header_dev" :items="$root.devices" :items-per-page="15" :search="search" :custom-filter="filter_dev" sort-by="mfr_timestamp" sort-desc>
					<template v-slot:top>
						<v-text-field v-model="search" label="Vyhledat" prepend-icon="mdi-magnify" class="mx-4" clearable></v-text-field>
					</template>
					<template v-slot:item.serial="{ item }">
						<serial-number :number="item.serial" />
					</template>
					<template v-slot:item.model="{ item }">
						<model-number :number="item.model.mpn" :ext="item.model_ext" />
					</template>
					<template v-slot:item.mfr_timestamp="{ item }">
						<cz-date :timestamp="item.mfr_timestamp" />
					</template>
					<template v-slot:item.state="{ item }">
						<maybe-shipped :device="item" />
					</template>
					<template v-slot:item.tags="{ item }">
						<table-tags :tags="item.tags" />
					</template>
					<template v-slot:item.gid="{ item }">
						<v-btn elevation="0" icon :href="'https://grafana.wafe.eu/d/_v5lhgPWz/detail-jednotky-ace?orgId=1&var-serial=' + item.serial" target="_blank" class="text--disabled">
							<v-icon>mdi-chart-line</v-icon>
						</v-btn>
						<v-btn elevation="0" icon :to="{name: 'device', params: {gid: item.gid}}">
							<v-icon>mdi-arrow-right</v-icon>
						</v-btn>
					</template>
				</v-data-table>
			</v-col>
			
			<v-col v-if="dataset == 'orgs'">
				<v-data-table ref="tbl" :headers="header_org" :items="$root.orgs" :items-per-page="15" :search="search" :custom-filter="filter_org" sort-by="gid" sort-desc>
					<template v-slot:top>
						<v-text-field v-model="search" label="Vyhledat" prepend-icon="mdi-magnify" class="mx-4" clearable></v-text-field>
					</template>
					
					<template v-slot:item.name="{ item }">
						{{ item.name }}
						<v-icon v-if="item.mgmt">mdi-shield-star-outline</v-icon>
					</template>
					
					<template v-slot:item.links="{ item }">
						<router-link :to="`/devices?search=${item.name}`">Jednotky</router-link>
						|
						<a target="_blank" :href="`https://grafana.wafe.eu/d/CsQIoPsWz/seznam-jednotek-ace-podle-organizace?orgId=1&var-org=${item.name}`">
							Grafana
							<v-icon small color="grey">mdi-open-in-new</v-icon>
						</a>
					</template>
					<template v-slot:item.gid="{ item }">
						<edit-new-org :org="item" btn-icon="mdi-home-edit-outline" />
					</template>
				</v-data-table>
			</v-col>
			
			<v-col v-if="dataset == 'users'">
				<v-data-table ref="tbl" :headers="header_usr" :items="$root.users" :items-per-page="15" :search="search" :custom-filter="filter_usr" sort-by="gid" sort-desc>
					<template v-slot:top>
						<v-text-field v-model="search" label="Vyhledat" prepend-icon="mdi-magnify" class="mx-4" clearable></v-text-field>
					</template>
					
					<template v-slot:item.login="{ item }">
						{{ item.login }}
						<v-icon v-if="item.iceberg_admin">mdi-shield-star-outline</v-icon>
					</template>
					
					<template v-slot:item.gid="{ item }">
						<edit-new-user :user="item" btn-icon="mdi-account-edit-outline" />
					</template>
				</v-data-table>
			</v-col>
			
		</v-row>
	</v-container>
</template>

<script>
import SerialNumber from "@/components/SerialNumber";
import ModelNumber from "@/components/ModelNumber";
import CzDate from "@/components/CzDate";
import MaybeShipped from "@/components/MaybeShipped";
import NewDevice from "@/components/NewDevice";
import EditNewOrg from "@/components/EditNewOrg";
import EditNewUser from "@/components/EditNewUser";
import TableTags from "@/components/TableTags";
import misc from "@/misc";

export default {
	components: {
		SerialNumber,
		ModelNumber,
		CzDate,
		MaybeShipped,
		NewDevice,
		EditNewOrg,
		EditNewUser,
		TableTags
	},
	props: {
		dataset: {
			type: String,
			required: true
		},
		heading: {
			type: String,
			required: true
		}
	},
	data () {
		return {
			search_state: "",
			header_dev: [
				{text: "Výrobní číslo", value: "serial", align: "start", width: 150},
				{text: "Model", value: "model", width: 180},
				{text: "Vyrobena", value: "mfr_timestamp"},
				{text: "Expedována", value: "state"},
				{text: "Uživatel", value: "org.name"},
				{text: "Správce", value: "mgmt_org.name"},
				{text: "Název", value: "name"},
				{text: "Štítky", value: "tags"},
				{text: "", value: "gid", sortable: false, align: "end"}
			],
			header_org: [
				{text: "Název", value: "name"},
				{text: "Poznámka", value: "note"},
				{text: "Kontakt", value: "email"},
				{text: "Odkazy", value: "links"},
				{text: "", value: "gid", sortable: false, align: "end"}
			],
			header_usr: [
				{text: "Login", value: "login"},
				{text: "Celé jméno", value: "display_name"},
				{text: "Zákazník", value: "org.name"},
				{text: "Poznámka", value: "note"},
				{text: "", value: "gid", sortable: false, align: "end"}
			]
		}
	},
	methods: {
		filter_dev: function (value, query_raw, item) {
			let query = query_raw.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();
			
			if (!query) {
				return true;
			}
			
			let query_words = query.split(/\s+/);
			let wordlist = [
				item.serial,
				item.model.mpn.toLowerCase(),
				misc.timestamp_to_cz_datetime(item.mfr_timestamp),
			];
			
			if (item.ssh_port) {
				wordlist.push(item.ssh_port.toString());
			}
			
			if (item.org) {
				wordlist.push(item.org.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase());
			}
			
			if (item.mgmt_org) {
				wordlist.push(item.mgmt_org.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase());
			}
			
			if (item.name) {
				wordlist.push(item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase());
			}
			
			if (item.note) {
				wordlist.push(item.note.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase());
			}
			
			for (let word of query_words) {
				if (wordlist.findIndex(w => w.includes(word)) == -1) {
					return false;
				}
			}
			
			return true;
		},
		filter_org: function (value, search_raw, item) {
			let search = search_raw.toLowerCase();
			
			if (item.name.toLowerCase().includes(search)) {
				return true;
			}
			
			if (item.note.toLowerCase().includes(search)) {
				return true;
			}
			
			return false;
		},
		filter_usr: function (value, search_raw, item) {
			let search = search_raw.toLowerCase();
			
			if (item.login.toLowerCase().includes(search)) {
				return true;
			}
			
			if (item.display_name && item.display_name.toLowerCase().includes(search)) {
				return true;
			}
			
			if (item.org && item.org.name.toLowerCase().includes(search)) {
				return true;
			}
			
			if (item.note && item.note.toLowerCase().includes(search)) {
				return true;
			}
			
			return false;
		},
		export_visible: function () {
			// this is REVOLTING but it works and I don't want to deal with this
			// javascript nonsense any longer than I need to
			let visible_items = this.$refs.tbl.$children[0].filteredItems;
			
			if (visible_items.length == 0) {
				return;
			}
			
			let columns = [];
			let csv_rows = [];
			
			// enumerate columns
			for (let key in visible_items[0]) {
				if (key == "tags") {
					continue;
				}
				
				columns.push(key);
			}
			
			csv_rows.push(columns.join(","));
			
			for (let item of visible_items) {
				let csv_cells = [];
				
				for (let key of columns) {
					let val = item[key];
					
					if (val == undefined) {
						val = "";
					}
					
					else if (key == "model") {
						val = val.mpn;
					}
					
					else if (key.endsWith("org")) {
						val = val.gid + " " + val.name;
					}
					
					csv_cells.push('"' + val.toString().trim().replace('"', "'") + '"');
				}
				
				csv_rows.push(csv_cells.join(","));
			}
			
			// determine the filename
			let filename = {
				"devices": "jednotek",
				"orgs": "organizací",
				"users": "uživatelů"
			}[this.dataset];
			
			if (this.search_state) {
				filename += " (" + this.search_state + ")";
			}
			
			let d = new Date();
			
			filename += " ze dne " + [
				d.getFullYear(),
				(d.getMonth() + 1).toString().padStart(2, "0"),
				d.getDate().toString().padStart(2, "0")
			].join("-") + " " + [
				d.getHours().toString().padStart(2, "0"),
				d.getMinutes().toString().padStart(2, "0"),
				d.getSeconds().toString().padStart(2, "0")
			].join(":");
			
			let a = window.document.createElement("a");
			a.href = window.URL.createObjectURL(new Blob([csv_rows.join("\r\n")], {type: "text/csv"}));
			a.download = "Export " + filename + ".csv";
			a.style.display = "none";
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			
			return true;
		}
	},
	computed: {
		search: {
			get () {
				return this.$route.query.search || this.search_state;
			},
			set (val) {
				this.search_state = val;
				
				if (val) {
					this.$router.replace(this.$route.path + "?search=" + val);
				} else {
					this.$router.replace(this.$route.path);
				}
			}
		}
	}
}
</script>
