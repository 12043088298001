<template>
	<v-container>
		<h1 class="text--disabled">Detail jednotky č. <serial-number :number="device.serial" /></h1>
		
		<v-row class="mt-0">
			<v-col cols="12" lg="7">
				<v-card elevation="16" class="rounded-lg">
					<v-card-text>
						<v-subheader>Identifikace</v-subheader>
						
						<v-row>
							<v-col cols="4" align="right">
								GID
							</v-col>
							<v-col>
								{{ gid }}
							</v-col>
						</v-row>
						
						<v-row>
							<v-col cols="4" align="right">
								Výrobní číslo
							</v-col>
							<v-col>
								<serial-number :number="device.serial" />
							</v-col>
						</v-row>
						
						<v-row>
							<v-col cols="4" align="right">
								Model
							</v-col>
							<v-col>
								<model-number :number="device.model.mpn" :ext="device.model_ext" />
							</v-col>
						</v-row>
						
						<v-row>
							<v-col cols="4" align="right">
								Vyrobena
							</v-col>
							<v-col>
								<cz-date :timestamp="device.mfr_timestamp" />
							</v-col>
						</v-row>
						
						<v-row>
							<v-col cols="4" align="right">
								Zákazníkův název
							</v-col>
							<v-col>
								<span>{{ device.name || '—' }}</span>
								<edit-text-popup :obj="device" endpoint="/device" prop="name" title="zákazníkova názvu" label="Zákazníkův název">
									Název, pod jakým zákazník vidí svoji jednotku v online systémech jako Iceberg <s>nebo MyWAFE</s>. Radši neměňte, pokud jednotku už zákazník používá.
								</edit-text-popup>
							</v-col>
						</v-row>
						
						<v-divider class="mt-4" />
						<v-subheader>Štítky</v-subheader>
						
						<device-detail-tag v-for="name in sorted_tags" :device="device" :tag="name" :key="name" />
						
						<v-row>
							<v-col>
								<v-btn x-small text @click="$set(device.tags, 'Nový štítek', ':-)')" class="float-right">
									přidat
								</v-btn>
							</v-col>
						</v-row>
						
						<v-divider class="mt-4" />
						<v-subheader>Logistika</v-subheader>
						
						<v-row>
							<v-col cols="4" align="right">
								Expedována
							</v-col>
							<v-col>
								<maybe-shipped :device="device" />
								<ship-device :device="device" />
							</v-col>
						</v-row>
						
						<v-row>
							<v-col cols="4" align="right">
								Distributor
							</v-col>
							<v-col>
								<span v-if="device.buyer_org">
									<router-link :to="`/orgs?search=${device.buyer_org.name}`">
										{{ device.buyer_org.name }}
									</router-link>
								</span>
								<span v-else>
									—
								</span>
								<edit-list-popup :obj="device" endpoint="/device" prop="buyer_org" :list="orgs" title="distributora" label="Distributor">
									Organizace, která jednotku koupila.
								</edit-list-popup>
							</v-col>
						</v-row>
						
						<v-row>
							<v-col cols="4" align="right">
								Správce
							</v-col>
							<v-col>
								<span v-if="device.mgmt_org">
									<router-link :to="`/orgs?search=${device.mgmt_org.name}`">
										{{ device.mgmt_org.name }}
									</router-link>
								</span>
								<span v-else>
									—
								</span>
								<edit-list-popup :obj="device" endpoint="/device" prop="mgmt_org" :list="orgs" title="správcovské organizace" label="Správce">
									Organizace, která má jednotku ve správě (např. facilities firma bytového domu).
								</edit-list-popup>
							</v-col>
						</v-row>
						
						<v-row>
							<v-col cols="4" align="right">
								Uživatel
							</v-col>
							<v-col>
								<span v-if="device.org">
									<router-link :to="`/orgs?search=${device.org.name}`">
										{{ device.org.name }}
									</router-link>
								</span>
								<span v-else>
									—
								</span>
								<edit-list-popup :obj="device" endpoint="/device" prop="org" :list="orgs" title="koncového uživatele" label="Uživatel">
									Koncový uživatel (organizace), který jednotku může obsluhovat.
								</edit-list-popup>
							</v-col>
						</v-row>
						
						<v-row>
							<v-col cols="4" align="right">
								Umístění
							</v-col>
							<v-col>
								<span>{{ device_location_abbr }}</span>
								<edit-text-popup :obj="device" endpoint="/device" prop="location" title="umístění jednotky" label="Umístění jednotky" :lines="4">
									Adresa a/nebo jiné označení místa, kde je jednotka nainstalována, např. patro, místnost, číslo parcely, atd.
								</edit-text-popup>
							</v-col>
						</v-row>
						
						<v-divider class="mt-4" />
						<v-subheader>Ovládání</v-subheader>
						
						<v-row>
							<v-col cols="4" align="right">
								Registrace MyNew
							</v-col>
							<v-col>
								<edit-bool :obj="device" prop="online_enabled" endpoint="/device" label-on="Povolena" label-off="Zakázána" />
							</v-col>
						</v-row>
						
						<v-row>
							<v-col cols="4" align="right">
								Přístupový kód
							</v-col>
							<v-col>
								{{ device.online_code || '—' }}
							</v-col>
						</v-row>
						
						<v-row>
							<v-col cols="4" align="right">
								SSH port
							</v-col>
							<v-col>
								{{ device.ssh_port }}
							</v-col>
						</v-row>
						
						<v-row>
							<v-col cols="4" align="right">
								Monitoring
							</v-col>
							<v-col>
								<a :href="'https://grafana.wafe.eu/d/_v5lhgPWz/detail-jednotky-ace?orgId=1&var-serial=' + device.serial" target="_blank">
									Otevřít v Grafaně
									<v-icon small color="grey">mdi-open-in-new</v-icon>
								</a>
							</v-col>
						</v-row>
						
						<v-row>
							<v-col cols="4" align="right">
								Nástroje
							</v-col>
							<v-col>
								<v-tooltip top>
									<template v-slot:activator="{on, attrs}">
										<v-btn v-bind="attrs" v-on="on" class="mr-4 my-4" @click="reboot()" :disabled="rebooting" :loading="rebooting">
											<v-icon left>mdi-restart</v-icon>
											Restart
										</v-btn>
									</template>
									<span>Běžný restart operačního systému. Obvykle řeší drobné softwarové poruchy.</span>
								</v-tooltip>
							</v-col>
						</v-row>
						
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" lg="5">
				<v-card outlined elevation="0" class="rounded-lg">
					<v-card-title class="text--disabled">Záznamy</v-card-title>
					<v-card-text>
						<p v-if="notes.length == 0" class="text-center font-italic">(zatím nic)</p>
						
						<div v-for="(item, index) in notes">
							<div class="d-flex mb-2">
								<v-avatar class="mr-4" v-bind="attrs" v-on="on" :color="item.color">
									<span class="white--text font-weight-bold">{{ item.initials }}</span>
								</v-avatar>
								<div class="flex-grow-1">
									<div class="devnote-author">
										<span>{{ item.author.display_name || item.author.login }}</span>
									</div>
									<div class="devnote-time">
										<cz-date :timestamp="item.timestamp" time />
										<v-btn v-if="item.can_delete" x-small text @click="note_delete(item)" class="text--disabled float-right">
											Smazat
										</v-btn>
									</div>
								</div>
							</div>
							<p class="ml-16">{{ item.text }}</p>
						</div>
					</v-card-text>
					<v-card-actions class="px-4">
						<v-textarea
							rows="1"
							auto-grow
							v-model="new_note_text"
							prepend-icon="mdi-notebook-edit-outline"
							append-outer-icon="mdi-send"
							clearable
							label="Nový záznam"
							type="text"
							@click:append-outer="note_send"
						/>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import SerialNumber from "@/components/SerialNumber";
import ModelNumber from "@/components/ModelNumber";
import CzDate from "@/components/CzDate";
import MaybeShipped from "@/components/MaybeShipped";
import EditTextPopup from "@/components/EditTextPopup";
import EditListPopup from "@/components/EditListPopup";
import ShipDevice from "@/components/ShipDevice";
import EditBool from "@/components/EditBool";
import DeviceDetailTag from "@/components/DeviceDetailTag";
import misc from "@/misc";

const LOCATION_MAX_LENGTH = 40;

export default {
	components: {
		SerialNumber,
		ModelNumber,
		CzDate,
		MaybeShipped,
		EditTextPopup,
		EditListPopup,
		ShipDevice,
		DeviceDetailTag,
		EditBool
	},
	props: {
		gid: {
			type: Number,
			required: true,
			validator: function (value) {
				return value > 0;
			}
		}
	},
	data () {
		return {
			checkbox: true,
			notes: [],
			new_note_text: "",
			rebooting: false,
			device_cached: null
		}
	},
	watch: {
		gid: {
			immediate: true,
			handler: function () {
				this.note_reload();
			}
		},
		"$root.devices": function () {
			this.device_cached = this.$root.devices_idx[this.gid];
			this.note_reload();
		}
	},
	methods: {
		note_reload: function () {
			let inst = this;
			
			this.$root.api_call_get_array(`/device/${this.gid}/notes`, this.notes, null, function (note) {
				/**
				 * Compute can_delete, color and avatar.
				 */
				
				let tmp = misc.user_to_avatar(note.author);
				note.initials = tmp.initials;
				note.color = tmp.color;
				note.can_delete = note.removable && (note.author.gid == inst.$root.identity.gid || inst.$root.identity.gid == 143);
			});
		},
		note_delete: function (note) {
			console.log("deleting note", note.gid);
			let index = this.notes.indexOf(note);
			let inst = this;
			
			this.$root.api_call_delete_object(
				`/note/${note.gid}`,
				function (response) {
					inst.notes.splice(index, 1);
				}
			);
		},
		note_send: function () {
			let note = {
				removable: true,
				text: this.new_note_text
			};
			
			let inst = this;
			
			this.$root.api_call_post_object(
				`/device/${this.gid}/note`,
				note,
				function () {
					inst.new_note_text = "";
					inst.note_reload();
				}
			);
		},
		reboot: function () {
			this.rebooting = true;
			let inst = this;
			
			this.$root.api_call_put_object(`/device/${this.device.gid}/reboot`, {}, function (response) {
					// on api success
					inst.rebooting = false;
					
					if (response.data.success) {
						// on command success
						inst.$root.$emit(
							"notify-user",
							"Jednotka přijala povel k restartu. Teď je to v rukou Božích. Měla by se probrat do 5 minut.",
							"Restart probíhá",
							30000,
							"success",
							"mdi-christianity"
						);
					} else {
						// on command failure
						inst.$root.$emit("notify-user", "Jednotka na povel neodpověděla. Buď je offline, nebo hůř.", "Nelze restartovat", 0);
					}
				}
			);
		}
	},
	computed: {
		device: function () {
			// hold a reference to the device obj during state reloads
			let tmp = this.$root.devices_idx[this.gid];
			
			if (tmp) {
				this.device_cached = tmp;
			}
			
			return this.device_cached;
		},
		orgs: function () {
			return this.$root.orgs;
		},
		sorted_tags: function () {
			let names = [misc.protected_tag_name];
			
			Object.entries(this.device.tags).sort().forEach((item) => {
				let key = item[0];
				
				if (key == misc.protected_tag_name) {
					return;
				}
				
				names.push(key);
			});
			
			return names;
		},
		device_location_abbr: function () {
			let loc = this.device.location || "—";
			
			if (loc.length > LOCATION_MAX_LENGTH) {
				loc = loc.slice(0, LOCATION_MAX_LENGTH) + "…";
			}
			
			return loc;
		}
	}
};
</script>
