<template>
	<v-dialog v-model="dialog" persistent max-width="512">
		<template v-slot:activator="{ on, attrs }">
			<v-btn v-if="true" x-small text v-bind="attrs" v-on="on" class="float-right">
				{{ (device.exw_timestamp === null)? 'Expedovat' : 'Změnit' }}
			</v-btn>
		</template>
		<v-card>
			<v-card-title>
				<span class="headline">Expedice jednotky {{ device.serial }}</span>
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-row>
						<v-col>
							<p>V tomto kroku se jednotka permanentně označí jako expedovaná.</p>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-menu
								v-model="picker_visible"
								:close-on-content-click="false"
								:nudge-right="0"
								transition="scale-transition"
								offset-y
								min-width="auto"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="date_cz"
										label="Datum expedice"
										prepend-icon="mdi-calendar"
										readonly
										v-bind="attrs"
										v-on="on"
									/>
								</template>
								<v-date-picker
									v-model="date"
									@input="picker_visible = false"
									show-adjacent-months
									first-day-of-week="1"
									:day-format="date_fmt"
								/>
							</v-menu>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
			<v-card-actions class="pa-4">
				<v-spacer></v-spacer>
				<v-btn text @click="dialog = false" :disabled="processing">Zrušit</v-btn>
				<v-btn :disabled="processing" :loading="processing" color="primary" @click="save">Expedovat</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import misc from "@/misc";

export default {
	props: {
		device: {
			type: Object,
			required: true
		}
	},
	data: function () {
		return {
			date: "1970-01-01",
			dialog_visible: false,
			picker_visible: false,
			processing: false
		}
	},
	computed: {
		dialog: {
			get () {
				return this.dialog_visible;
			},
			set (visible) {
				if (visible) {
					this.reset();
				}
				
				this.dialog_visible = visible;
			}
		},
		date_cz: function () {
			let timestamp = (new Date(this.date).getTime()) / 1000;
			return misc.timestamp_to_cz_datetime(timestamp, this.time);
		}
	},
	methods: {
		reset: function () {
			this.processing = false;
			this.date = new Date().toISOString().substr(0, 10);
		},
		save: function () {
			this.processing = true;
			
			let timestamp = (new Date(this.date).getTime()) / 1000 + 43200; // move it to mid-day
			this.device.exw_timestamp = timestamp;
			this.device.state = 3;
			
			let inst = this;
			
			this.$root.api_call_put_object("/device", this.device, function (response) {
				inst.processing = true;
				inst.dialog = false;
			},
			function () {
				inst.device.exw_timestamp = null;
				this.device.state = 2;
			});
		},
		date_fmt: function (date) {
			return parseInt(date.split("-")[2]);
		}
	}
};
</script>
