/**
 * Returns a 3-digit hex color value corresponding to the word given.
 *
 * @param      {str}  word    The word
 * @param      {int}  range   Maximum color value (e.g. 0x7ff)
 * @param      {int}  offset  Number to add to the color (e.g. 0x3ff)
 * @param      {int}  xor     Optional number to xor the result with
 */
function word_to_color (word, range, offset, xor) {
	let code = word.charCodeAt(0);
	
	for (let i = 1; i < word.length; i++) {
		code *= word.charCodeAt(i);
	}
	
	code %= range;
	
	if (xor) {
		code ^= xor;
	}
	
	return "#" + (code + offset).toString(16).padStart(3, "0");
}

function user_to_avatar (user) {
	let name = (user.display_name || user.login).toUpperCase();
	let words = name.split(" ");
	let initials;
	
	if (words.length >= 2) {
		initials = words[0][0] + words[words.length - 1][0];
	} else {
		initials = name.slice(0, 2);
	}
	
	return {
		initials: initials,
		color: word_to_color(initials, 0xfff, 0x0, 0xfff)
	}
}

const months = [
	"ledna",
	"února",
	"března",
	"dubna",
	"května",
	"června",
	"července",
	"srpna",
	"září",
	"října",
	"listopadu",
	"prosince"
];

/**
 * Formats a unix timestamp into a local Czech datetime.
 *
 * @param      {number}  timestamp  time_t
 * @param      {<type>}  time       true to append hh:mm 
 */
function timestamp_to_cz_datetime (timestamp, time) {
	let dt = new Date(timestamp * 1000);
	let Y = dt.getFullYear();
	let m = months[dt.getMonth()];
	let d = dt.getDate();
	let H = dt.getHours().toString().padStart(2, "0");
	let M = dt.getMinutes().toString().padStart(2, "0");
	let S = dt.getSeconds().toString().padStart(2, "0");
	
	if (time) {
		return `${d}. ${m} ${Y} v ${H}:${M}`;
	} else {
		return `${d}. ${m} ${Y}`;
	}
}

/**
 * Device tag which can't be deleted.
 */
const protected_tag_name = "Poznámka";

/**
 * Returns how many times item exists in arr.
 *
 * @param      Array  arr     The array
 * @param      Any  item    The item
 */
function count (arr, item) {
	let n = 0;
	
	for (let i = 0; i < arr.length; i++) {
		if (arr[i] == item) {
			n++;
		}
	}
	
	return n;
}

/**
 * Returns the text with the first letter uppercase and all others lowercase.
 */
function capitalize (text) {
	return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

function get_prefixes (items, ptrs) {
	let prefixes = [];
	
	for (let i = 0; i < items.length; i++) {
		prefixes.push(items[i].slice(0, ptrs[i]).trim().toUpperCase());
	}
	
	return prefixes;
}

function shortest_unique_prefixes (items) {
	let ptrs = Array(items.length).fill(1);
	let prefixes;
	let done = false;
	
	while (!done) {
		prefixes = get_prefixes(items, ptrs);
		
		done = true;
		for (let i = 0; i < items.length; i++) {
			if (count(prefixes, prefixes[i]) > 1) {
				ptrs[i]++;
				done = false;
			}
		}
	}
	
	return prefixes;
}

export default {
	capitalize,
	word_to_color,
	user_to_avatar,
	timestamp_to_cz_datetime,
	protected_tag_name,
	shortest_unique_prefixes
};
