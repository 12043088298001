<template>
	<span>
		{{ date_cz }}
	</span>
</template>

<script>
import misc from "@/misc";

export default {
	props: {
		timestamp: {
			type: Number,
			validator: function (value) {
				return value > 0;
			}
		},
		time: {
			type: Boolean,
			required: false
		}
	},
	data: function () {
		return {
			
		}
	},
	computed: {
		date_cz: function () {
			if (this.timestamp) {
				return misc.timestamp_to_cz_datetime(this.timestamp, this.time);
			} else {
				return "—";
			}
		}
	}
};
</script>
