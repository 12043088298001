<template>
	<v-dialog v-model="dialog" :persistent="save_required" max-width="800">
		<template v-slot:activator="{ on, attrs }">
			<v-btn v-if="btnText" color="primary" v-bind="attrs" v-on="on">
				<v-icon left>{{ btnIcon }}</v-icon>
				<span>{{ btnText }}</span>
			</v-btn>
			<v-btn v-else elevation="0" icon v-bind="attrs" v-on="on">
				<v-icon>{{ btnIcon }}</v-icon>
			</v-btn>
		</template>
		
		<v-card>
			<v-form v-model="valid" autocomplete="off">
				<v-card-title>
					<span v-if="user" class="headline">Úprava uživatele <span class="text--disabled">#{{ user.gid }}</span> {{ display_name || login }}</span>
					<span v-else class="headline">Nový uživatelský účet</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col>
								<p>Uživatelský účet reprezentuje fyzickou osobu, která se pomocí jména a hesla přihlásí do systému a tím získá přístup ke všem jednotkám organizace, pod kterou patří.</p>
								<p>U zákazníků typicky používáme jako přihlašovací jméno jejich e-mail, aby bylo možné je snadno kontaktovat a např. jim poslat jejich první heslo. Není to ale podmínkou — jménem může být libovolné unikátní slovo.</p>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-text-field label="Přihlašovací jméno" v-model="login" @keydown.enter="$refs.dn.focus()" autofocus :rules="[validate_login, validate_length]" autocomplete="off" />
								<v-text-field ref="dn" label="Celé jméno" v-model="display_name" @keydown.enter="$refs.ph.focus()" clearable autocomplete="off" />
								<v-text-field ref="ph" label="Telefon" v-model="phone" @keydown.enter="$refs.org.focus()" clearable autocomplete="off" />
								<v-autocomplete
									ref="org"
									:items="orgs_sorted"
									:filter="org_filter"
									item-text="name"
									return-object
									label="Organizace"
									v-model="org"
									@keydown.enter="$refs.note.focus()"
									autocomplete="off"
								/>
								<v-text-field ref="note" label="Poznámka" v-model="note" @keydown.enter="save" clearable autocomplete="off" />
								<v-text-field
									v-model="password"
									:label="(user)? 'Změnit heslo (necháte-li prázdné, nebude změněno)' : 'Heslo'"
									:type="password_visible? 'text' : 'password'"
									:append-icon="password_visible? 'mdi-eye-off' : 'mdi-eye'"
									@click:append="password_visible = !password_visible"
									autocomplete="new-password"
									:loading="password_loading"
								/>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-checkbox :disabled="!mail_valid" v-model="mail_send" label="Chci poslat uživateli e-mail"></v-checkbox>
							</v-col>
							<v-col>
								<v-checkbox v-model="iceberg_admin" label="Iceberg správce"></v-checkbox>
							</v-col>
						</v-row>
						<template v-if="mail_send">
							<v-row>
								<v-col>
									<v-btn-toggle v-model="mail_selected_company" mandatory>
										<v-btn>WAFE</v-btn>
										<v-btn>Entalpa</v-btn>
									</v-btn-toggle>
								</v-col>
								<v-col>
									<v-btn-toggle v-model="mail_selected_app" mandatory>
										<v-btn>MyWAFE</v-btn>
										<v-btn>Iceberg</v-btn>
									</v-btn-toggle>
								</v-col>
								<v-col>
									<v-btn-toggle v-model="mail_selected_language" mandatory>
										<v-btn>CZ</v-btn>
										<v-btn>EN</v-btn>
									</v-btn-toggle>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-text-field label="Předmět" v-model="mail_subject" />
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-textarea rows="10" label="Text e-mailu" v-model="mail_text" prepend-icon="mdi-email-open-outline" />
								</v-col>
							</v-row>
						</template>
					</v-container>
				</v-card-text>
				<v-card-actions class="pa-4">
					<v-btn v-if="user" text @click="new_password" :disabled="loading">Resetovat heslo</v-btn>
					<v-spacer></v-spacer>
					<v-btn text @click="dialog = false" :disabled="loading">Zrušit</v-btn>
					<v-btn :disabled="!valid || !save_required || loading" color="primary" @click="save" :loading="loading">{{ (user)? 'Uložit' : 'Založit' }}</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
const LOGIN_MIN_LENGTH = 4;

const mail_regexp = new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+[.][a-zA-Z0-9.-]{2,}$");

const mail_subject_templates = {};
mail_subject_templates.cz = "Přístup do aplikace _APPNAME_SHORT_";
mail_subject_templates.en = "_APPNAME_SHORT_ application access";

const mail_templates = {};
mail_templates.cz = `Dobrý den,

Vaše přístupové údaje pro aplikaci _APPNAME_ jsou:
jméno: _LOGIN_
heslo: _PASSWORD_

Doporučujeme heslo po přihlášení co nejdříve změnit (Profil -> Změna hesla).

S pozdravem,
_COMPANY_

Toto je automatický e-mail, proto na něj prosím neodpovídejte.
`;

mail_templates.en = `Greetings,

your credentials for the _APPNAME_ application are:
name: _LOGIN_
password: _PASSWORD_

We advise you to change the password after logging in (Profile -> Change password).

Best regards,
_COMPANY_

This is an automated message, please do not reply to it.
`;

const mail_companies = {};
mail_companies.wafe = `WAFE s.r.o.
servis@wafe.cz`;

mail_companies.entalpa = `Entalpa s.r.o.
servis@entalpa.cz`;

const mail_apps = {};
mail_apps.mywafe = "MyWAFE (https://go2my.wafe.eu/)";
mail_apps.iceberg = "Iceberg (https://iceberg.wafe.eu/)";

export default {
	props: {
		btnIcon: {
			type: String,
			required: true
		},
		btnText: {
			type: String,
			required: false
		},
		user: {
			type: Object,
			required: false
		}
	},
	data: function () {
		return {
			loading: false,
			login: "",
			display_name: "",
			phone: null,
			org: null,
			note: "",
			dialog_: false,
			valid: false,
			password: "",
			password_visible: false,
			password_reloading: false,
			iceberg_admin: false,
			mail_send: false,
			mail_subject: "",
			mail_text: "",
			mail_selected_language: 0,
			mail_selected_company: 0,
			mail_selected_app: 0
		}
	},
	computed: {
		save_required: function () {
			if (this.user) {
				return Boolean(
					(this.login != this.user.login) ||
					(this.display_name != this.user.display_name) ||
					(this.phone != this.user.phone) ||
					(this.org.gid != this.user.org.gid) ||
					(this.note != this.user.note) ||
					(this.iceberg_admin != this.user.iceberg_admin) ||
					(this.password)
				);
			} else {
				return Boolean((this.login.length >= LOGIN_MIN_LENGTH) && (this.org));
			}
		},
		dialog: {
			get () {
				return this.dialog_;
			},
			set (visible) {
				if (visible) {
					this.reset();
				}
				
				this.dialog_ = visible;
			}
		},
		existing_logins_lower: function () {
			return this.$root.users.map((u) => u.login.toLowerCase());
		},
		orgs_sorted: function () {
			let copy = this.$root.orgs.slice();
			
			copy.sort(function (a, b) {
				let a_name = a.name.toLowerCase();
				let b_name = b.name.toLowerCase();
				
				if (a_name < b_name) return -1;
				else if (a_name > b_name) return 1;
				else return 0;
			});
			
			return copy;
		},
		mail_valid: function () {
			let valid = mail_regexp.test(this.login);
			
			if (this.mail_send && !valid) {
				this.mail_send = false;
			}
			
			return valid;
		},
		password_loading: function () {
			return Boolean((this.password_reloading || !this.user) && !this.password);
		},
		password_hint: function () {
			return (this.user)? "Necháte-li pole prázdné, nebude heslo změněno." : null;
		}
	},
	methods: {
		reset: function () {
			this.loading = false;
			this.login = (this.user)? this.user.login : "";
			this.display_name = (this.user)? this.user.display_name : "";
			this.phone = (this.user)? this.user.phone : "";
			this.org = (this.user)? this.user.org : null;
			this.note = (this.user)? this.user.note : "";
			this.iceberg_admin = (this.user)? this.user.iceberg_admin : false;
			this.password = "";
			
			// request a random password for new users
			if (!this.user) {
				let inst = this;
				
				setTimeout(function () {
					inst.$root.api_call_get_object("/auth/random-password", inst, "password", "password");
				}, 1000);
			}
		},
		save: function () {
			if (!this.valid) {
				return;
			}
			
			this.loading = true;
			
			let inst = this;
			let method = (this.user)? this.$root.api_call_put_object : this.$root.api_call_post_object;
			let obj = {
				login: this.login,
				display_name: this.display_name,
				org: this.org,
				note: this.note,
				iceberg_admin: this.iceberg_admin,
				phone: this.phone
			};
			
			if (this.password) {
				obj.password = this.password;
			}
			
			if (this.mail_send) {
				obj.email_subject = this.mail_subject;
				obj.email_text = this.mail_text;
			}
			
			if (this.user) {
				obj.gid = this.user.gid;
			}
			
			method("/user", obj, function (response) {
				// on success
				if (inst.user) {
					inst.user.login = inst.login;
					inst.user.display_name = inst.display_name;
					inst.user.phone = inst.phone;
					inst.user.org = inst.org;
					inst.user.note = inst.note;
					inst.user.iceberg_admin = inst.iceberg_admin;
				} else {
					inst.$root.refresh_global_state();
					inst.$router.push(`/users?search=${inst.login}`);
				}
				
				inst.dialog = false;
			},
			function () {
				// on failure
			})
		},
		validate_login: function (login) {
			if (this.user && this.user.login.toLowerCase() == login.toLowerCase()) {
				return true;
			}
			
			if (this.existing_logins_lower.includes(login.toLowerCase())) {
				return "Tento uživatel už existuje.";
			}
			
			return true;
		},
		validate_length: function (login) {
			return login.length >= LOGIN_MIN_LENGTH;
		},
		org_filter: function (item, query_text, item_text) {
			let query_ascii = query_text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
			let item_ascii = item_text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
			
			return item_ascii.indexOf(query_ascii) > -1;
		},
		mail_from_template: function () {
			if (this.mail_send) {
				let template = (this.mail_selected_language == 0)? mail_templates.cz : mail_templates.en;
				let company = (this.mail_selected_company == 0)? mail_companies.wafe : mail_companies.entalpa;
				let appname = (this.mail_selected_app == 0)? mail_apps.mywafe : mail_apps.iceberg;
				
				let text = template.replaceAll("_APPNAME_", appname);
				text = text.replaceAll("_COMPANY_", company);
				text = text.replaceAll("_LOGIN_", this.login);
				text = text.replaceAll("_PASSWORD_", this.password);
				
				this.mail_text = text;
				
				let subject = (this.mail_selected_language == 0)? mail_subject_templates.cz : mail_subject_templates.en;
				subject = subject.replaceAll("_APPNAME_SHORT_", appname.split(" ")[0]);
				
				this.mail_subject = subject;
			}
		},
		new_password: function () {
			this.password_reloading = true;
			this.password = "";
			let inst = this;
			
			setTimeout(function () {
				inst.$root.api_call_get_object("/auth/random-password", inst, "password", "password");
			}, 2000);
		}
	},
	watch: {
		mail_selected_app: function () {
			this.mail_from_template();
		},
		mail_selected_company: function () {
			this.mail_from_template();
		},
		mail_selected_language: function () {
			this.mail_from_template();
		},
		mail_send: function () {
			this.mail_from_template();
		},
		password: function () {
			this.mail_from_template();
		},
		login: function () {
			this.mail_from_template();
		}
	}
};
</script>

<style scoped>
.bs-alert {
	opacity: 0.0;
	transition: opacity 0.5s;
}

.bs-alert-visible {
	opacity: 1.0;
}
</style>
