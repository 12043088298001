<template>
	<span>
		<v-simple-checkbox v-model="state" class="d-inline mr-1" :indeterminate="loading" /> {{ label }}
	</span>
</template>

<script>
import misc from "@/misc";

export default {
	props: {
		obj: {
			type: Object,
			required: true
		},
		prop: {
			type: String,
			required: true
		},
		endpoint: {
			type: String,
			required: true,
			validator: function (value) {
				return value && value[0] == "/";
			}
		},
		labelOn: {
			type: String,
			required: true
		},
		labelOff: {
			type: String,
			required: true
		}
	},
	data: function () {
		return {
			loading: false
		}
	},
	computed: {
		state: {
			get () {
				return this.obj[this.prop];
			},
			set (value) {
				this.loading = true;
				this.obj[this.prop] = value;
				
				let inst = this;
				
				this.$root.api_call_put_object("/device", this.obj, function (response) {
					inst.loading = false;
				},
				function () {
					inst.loading = false;
					inst.obj[inst.prop] = !inst.obj[inst.prop];
				})
			}
		},
		label: function () {
			if (this.state) {
				return this.labelOn;
			} else {
				return this.labelOff;
			}
		}
	}
};
</script>
