<template>
	<v-dialog v-model="dialog" :persistent="save_required" max-width="640">
		<template v-slot:activator="{ on, attrs }">
			<v-btn v-if="btnText" color="primary" v-bind="attrs" v-on="on">
				<v-icon>{{ btnIcon }}</v-icon>
				<span>{{ btnText }}</span>
			</v-btn>
			<v-btn v-else elevation="0" icon v-bind="attrs" v-on="on">
				<v-icon>{{ btnIcon }}</v-icon>
			</v-btn>
		</template>
		
		<v-card>
			<v-form v-model="valid">
				<v-card-title>
					<span v-if="org" class="headline">Úprava organizace <span class="text--disabled">#{{ org.gid }}</span> {{ name }}</span>
					<span v-else class="headline">Nová organizace</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col>
								<p>Organizace je entita, která vlastní jednu nebo více jednotek, a zároveň sdružuje jednoho nebo více uživatelů.</p>
								<p>Servisní organizace provádí dohled nad jednotkami jiných zákazníků.</p>
								<p>Název organizace vidí uživatel po přihlášení na Iceberg. Poznámka je jen pro nás, je vidět v tabulce a lze podle ní vyhledávat.</p>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-text-field label="Název" v-model="name" @keydown.enter="$refs.email.focus()" autofocus :rules="[validate_name, validate_length]" />
								<v-text-field ref="email" label="Kontaktní e-mail" v-model="email" @keydown.enter="$refs.note.focus()" clearable />
								<v-text-field ref="note" label="Poznámka" v-model="note" @keydown.enter="save" clearable />
								<v-checkbox v-model="mgmt" label="Správcovská organizace" />
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions class="pa-4">
					<v-spacer></v-spacer>
					<v-btn text @click="dialog = false" :disabled="loading">Zrušit</v-btn>
					<v-btn :disabled="!valid || !save_required || loading" color="primary" @click="save" :loading="loading">{{ (org)? 'Uložit' : 'Vytvořit' }}</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
const NAME_MIN_LENGTH = 4;

export default {
	props: {
		btnIcon: {
			type: String,
			required: true
		},
		btnText: {
			type: String,
			required: false
		},
		org: {
			type: Object,
			required: false
		}
	},
	data: function () {
		return {
			loading: false,
			name: "",
			email: "",
			note: "",
			mgmt: false,
			dialog_: false,
			valid: false
		}
	},
	computed: {
		save_required: function () {
			if (this.org) {
				return (this.name != this.org.name) || (this.note != this.org.note) || (this.email != this.org.email) || (this.mgmt != this.org.mgmt);
			} else {
				return (this.name.length >= NAME_MIN_LENGTH);
			}
		},
		dialog: {
			get () {
				return this.dialog_;
			},
			set (val) {
				this.reset();
				this.dialog_ = val;
			}
		},
		existing_orgs_lower: function () {
			return this.$root.orgs.map((o) => o.name.toLowerCase());
		}
	},
	methods: {
		reset: function () {
			this.loading = false;
			this.name = (this.org)? this.org.name : "";
			this.note = (this.org)? this.org.note : "";
			this.email = (this.org)? this.org.email : "";
			this.mgmt = (this.org)? this.org.mgmt : false;
		},
		save: function () {
			if (!this.valid) {
				return;
			}
			
			this.loading = true;
			
			let inst = this;
			let method = (this.org)? this.$root.api_call_put_object : this.$root.api_call_post_object;
			let obj = {
				name: this.name,
				note: this.note,
				email: this.email,
				mgmt: this.mgmt
			};
			
			if (this.org) {
				obj.gid = this.org.gid;
			}
			
			method("/org", obj, function (response) {
				// on success
				if (inst.org) {
					inst.org.name = inst.name;
					inst.org.note = inst.note;
					inst.org.email = inst.email;
					inst.org.mgmt = inst.mgmt;
				} else {
					inst.$root.refresh_global_state();
					inst.$router.push(`/orgs?search=${inst.name}`);
				}
				
				inst.dialog = false;
			},
			function () {
				// on failure
			})
		},
		validate_name: function (name) {
			if (this.org && this.org.name.toLowerCase() == name.toLowerCase()) {
				return true;
			}
			
			if (this.existing_orgs_lower.includes(name.toLowerCase())) {
				return "Organizace s tímto názvem už existuje.";
			}
			
			return true;
		},
		validate_length: function (name) {
			return name.length >= NAME_MIN_LENGTH;
		}
	}
};
</script>

<style scoped>
.bs-alert {
	opacity: 0.0;
	transition: opacity 0.5s;
}

.bs-alert-visible {
	opacity: 1.0;
}
</style>
