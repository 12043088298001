<template>
	<v-container fill-height>
		<v-row align="center" justify="center">
			<v-col></v-col>
			<v-col cols="10" md="6">
				<v-sheet elevation="24" rounded class="rounded-lg pa-6">
					<div class="pa-4 logo" :class="logo_class">
						<v-row>
							<v-col class="d-flex flex-column align-center">
								<v-img width="160" aspect-ratio="1.0" :src="logo_url" />
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<h2 align=center>WAFE Backstage</h2>
							</v-col>
						</v-row>
					</div>
					
					<v-form ref="form">
						<v-text-field
							v-model="username"
							label="Jméno"
							required
							@focus="flip_logo(0)"
							@keydown.enter="$refs.password.focus()"
						/>
						<v-text-field
							v-model="password"
							ref="password"
							label="Heslo"
							:type="password_visible? 'text' : 'password'"
							required
							:append-icon="password_visible? 'mdi-eye-off' : 'mdi-eye'"
							@click:append="password_visible = !password_visible"
							@focus="flip_logo(1)"
							@keydown.enter="do_login"
						/>
						<v-btn block color="primary" :disabled="login_disabled || processing" :loading="processing" large @click="do_login">
							<v-icon left>mdi-login</v-icon>
							<span>Přihlásit</span>
						</v-btn>
					</v-form>
				</v-sheet>
				<v-alert :class="['mt-8', 'bs-alert', {'bs-alert-visible': alert.visible}]" dense type="error">{{ alert.text }}</v-alert>
			</v-col>
			<v-col></v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	data: function () {
		return {
			i: 0,
			alert: {
				visible: false,
				code: null,
				text: null
			},
			processing: false,
			username: "",
			password: "",
			password_visible: false,
			logo_class: ""
		}
	},
	computed: {
		login_disabled: function () {
			return (this.username.length < 2 || this.password.length < 5);
		},
		logo_url: function () {
			if (this.$vuetify.theme.dark) {
				return require("../assets/wafe-logo.dark.png");
			} else {
				return require("../assets/wafe-logo.light.png");
			}
		}
	},
	methods: {
		do_login () {
			this.processing = true;
			this.$root.login(this.username, this.password, this.alert);
		},
		flip_logo (flipped) {
			if (flipped) {
				this.logo_class = "flipped";
			} else {
				this.logo_class = "";
			}
		}
	},
	mounted() {
		// show progress if auto login is already underway
		if (this.$root.token) {
			this.processing = true;
		}
	},
	watch: {
		"alert.code": function (val) {
			if (val) {
				if (val == 401) {
					this.alert.text = "Přístup byl zamítnut";
				} else if (this.alert.code == -1) {
					this.alert.text = "Nelze se spojit se serverem";
				} else {
					this.alert.text = "Došlo k chybě " + val;
				}
				
				this.alert.visible = true;
			} else {
				this.alert.visible = false;
			}
			
			this.processing = false;
		},
		"$root.data_loaded": function (val) {
			if (val) {
				console.log("All data loaded, redirecting to", this.$route.query.redirect || "/");
				this.$router.push(this.$route.query.redirect || "/");
			}
		}
	}
};
</script>

<style scoped>
.bs-alert {
	opacity: 0.0;
	transition: opacity 0.5s;
}

.bs-alert-visible {
	opacity: 1.0;
}

.logo {
	transition: transform 1s;
}

.flipped {
	transform: rotateY(180deg);
}
</style>