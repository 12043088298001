<template>
	<span>
		<v-dialog v-model="dialog" width="420">
			<v-card>
				<v-card-title class="decoded">
					<span class="mfr">{{ mfr }}</span>
					<span class="size">{{ size }}</span>
					<span class="location">{{ location }}</span>
					<span class="exchanger">{{ exchanger }}</span>
					<span class="controller">{{ controller }}</span>
					<span class="region">{{ region }}</span>
					<span class="enclosure">{{ enclosure }}</span>
					<span class="heater">{{ heater }}</span>
					<span class="ace">{{ ace }}</span>
					<span class="fans">{{ fans }}</span>
					<span class="btns">{{ btns }}</span>:
				</v-card-title>
				<v-card-text class="decoded">
					<ul>
						<li>vyrábí ji <span class="mfr">{{ mfr_cz }}</span></li>
						<li>jde o typ <span class="size">W{{ Number(size) }}</span></li>
						<li>má <span class="location">{{ location_cz }}</span> topologii</li>
						<li>používá <span class="exchanger">{{ exchanger_cz }}</span></li>
						<li>je řízena <span class="controller">{{ controller_cz }}</span></li>
						<li>je určena pro <span class="region">{{ region }}</span> region</li>
						<li>opláštěna <span class="enclosure">{{ enclosure_cz }}</span></li>
						<li><span class="heater">{{ heater_cz }}</span></li>
						<li>ACE řízení je <span class="ace">{{ ace_cz }}</span></li>
						<li>ventilátory má <span class="fans">{{ fans_cz }}</span></li>
						<li>digitální vstupy jsou pro <span class="btns">{{ btns_cz }}</span></li>
					</ul>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary" text @click="dialog = false">Zavřít</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-btn v-if="activator" text @click="dialog = true">{{ activator }}</v-btn>
		<span v-else>
			<span @click="dialog = true" class="compact">
				<span>{{ number }}</span>
			</span>
			<span v-if="ext">-{{ ext }}</span>
		</span>
	</span>
</template>

<script>
const schema = {
	mfr: {
		"W": "WAFE s.r.o.",
		"@": "neznámý"
	},
	location: {
		"C": "centrální",
		"D": "decentrální",
		"S": "střešní",
		"@": "neznámou"
	},
	exchanger: {
		"C": "HRV výměník s kompresorem",
		"D": "ERV výměník s kompresorem",
		"E": "ERV s entalpickým výměníkem",
		"F": "EFS s klapkovým systémem a HRV výměníkem",
		"H": "HRV s deskovým výměníkem",
		"@": "neznámou technologii"
	},
	controller: {
		"A": "ACE se snímači 4× T+H, 1× CO2",
		"K": "ACE se snímači 4× T+H, 1× CO2, 1× kouř",
		"E": "ACE se snímači 4× NTC12k",
		"e": "ACE se snímači 4× NTC10k",
		"D": "ACE se snímači 4× NTC12k, 1× CO2",
		"d": "ACE se snímači 4× NTC10k, 1× CO2",
		"H": "ACE se snímači 4× NTC12k, 1× CO2, 1× kouř",
		"h": "ACE se snímači 4× NTC10k, 1× CO2, 1× kouř",
		"G": "ACE se snímači 3× NTC12k, 1× T+H (pool)",
		"g": "ACE se snímači 3× NTC10k, 1× T+H (pool)",
		"I": "ACE se snímači 2× NTC12k, 2× T+H, 1× CO2",
		"i": "ACE se snímači 2× NTC10k, 2× T+H, 1× CO2",
		"J": "ACE se snímači 2× NTC12k, 2× T+H, 1× CO2, 1× kouř",
		"j": "ACE se snímači 2× NTC10k, 2× T+H, 1× CO2, 1× kouř",
		"S": "Eltis se snímačem 1× T",
		"X": "Arduino se snímači 4× T+H, 1× CO2",
		"Y": "Arduino se snímači 4× T+H",
		"@": "neznámo čím"
	},
	enclosure: {
		"A": "je po celém povrchu",
		"B": "je pouze na pohledové straně",
		"W": "není",
		"@": "je neznámo jak"
	},
	heater: {
		"M": "má 3-článkový dohřev",
		"N": "nemá předehřev ani dohřev",
		"O": "má 3-článkový předehřev i dohřev",
		"P": "má předehřev s 5 nebo 6 články",
		"Q": "má 3-článkový předehřev",
		"@": "???"
	},
	fans: {
		"0": "standardní",
		"1": "ve variantě #1",
		"2": "ve variantě #2",
		"@": "???"
	},
	ace: {
		"1": "1. generace",
		"2": "2. generace",
		"@": "???"
	},
	btns: {
		"0": "RD standard",
		"1": "školy",
		"2": "školy (s detekcí otevření krytu)",
		"@": "???"
	}
};

function decode_mpn_part (code, subtree) {
	if (code in subtree) {
		return subtree[code];
	} else {
		return subtree["@"];
	}
}

export default {
	props: {
		number: {
			type: String,
			required: true
		},
		ext: {
			type: String,
			required: false
		},
		activator: {
			type: String,
			required: false
		}
	},
	data: function () {
		return {
			dialog: false
		}
	},
	computed: {
		mfr: function () {
			return this.number.slice(0, 1);
		},
		mfr_cz: function () {
			return decode_mpn_part(this.mfr, schema.mfr);
		},
		size: function () {
			return this.number.slice(1, 5);
		},
		location: function () {
			return this.number.slice(5, 6);
		},
		location_cz: function () {
			return decode_mpn_part(this.location, schema.location);
		},
		exchanger: function () {
			return this.number.slice(6, 7);
		},
		exchanger_cz: function () {
			return decode_mpn_part(this.exchanger, schema.exchanger);
		},
		controller: function () {
			return this.number.slice(7, 8);
		},
		controller_cz: function () {
			return decode_mpn_part(this.controller, schema.controller);
		},
		region: function () {
			return this.number.slice(8, 10);
		},
		enclosure: function () {
			return this.number.slice(10, 11);
		},
		enclosure_cz: function () {
			return decode_mpn_part(this.enclosure, schema.enclosure);
		},
		heater: function () {
			return this.number.slice(11, 12);
		},
		heater_cz: function () {
			return decode_mpn_part(this.heater, schema.heater);
		},
		ace: function () {
			return this.number.slice(12, 13) || "1";
		},
		ace_cz: function () {
			return decode_mpn_part(this.ace, schema.ace);
		},
		fans: function () {
			return this.number.slice(13, 14) || "0";
		},
		fans_cz: function () {
			return decode_mpn_part(this.fans, schema.fans);
		},
		btns: function () {
			return this.number.slice(14, 15) || "0";
		},
		btns_cz: function () {
			return decode_mpn_part(this.btns, schema.btns);
		}
	}
};
</script>

<style lang="scss" scoped>
.compact {
	cursor: pointer;
	
	&:hover {
		text-decoration: underline;
	}
}

.decoded {
	.mfr {
		color: #5b9bd5;
	}
	
	.size {
		color: #ff0000;
	}
	
	.location {
		color: #70ad47;
	}
	
	.exchanger {
		color: #ff00ff;
	}
	
	.controller {
		color: #ff9900;
	}
	
	.region {
		color: #9900ff;
	}
	
	.enclosure {
		color: #9e8128;
	}
	
	.heater {
		font-weight: bold;
	}
	
	.ace {
		color: #444fff;
	}
	
	.fans {
		color: #00ff4f;
	}
	
	.btns {
		color: #4f8800;
	}
}
</style>