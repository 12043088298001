import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import axios from "axios";
import misc from "@/misc";

Vue.config.productionTip = false;

let API = axios.create({
	baseURL: "https://backstage.wafe.eu/api/1"
});

Vue.prototype.$API = API;

let app = new Vue({
	router,
	vuetify,
	render: h => h(App),
	data: function () {
		return {
			// token_value: "",
			token: "",
			identity: null,
			models: [],
			models_idx: {},
			devices: [],
			devices_idx: {},
			orgs: [],
			orgs_idx: {},
			users: [],
			users_idx: {},
			tags_abbr: {}
		}
	},
	methods: {
		/**
		 * Handles an API call error.
		 *
		 * If no error_out is passed, decodes the error into a human-readable
		 * message and displays it in a notification.
		 *
		 * If error_out is an object, writes an error code into its .code member
		 * instead.
		 *
		 * @param      {object}  error      The error
		 * @param      {object}  error_out  The error out
		 */
		api_call_on_error: function (error, error_out) {
			let msg = [];
			let code;
			
			console.error(error);
			
			if (error.response) {
				code = error.response.status;
				
				if (error.response.status >= 500) {
					msg = [
						"Server při zpracování dotazu " + error.request.responseURL +
						" vrátil chybový kód " + error.response.status
						+ ". Kontaktujte provozovatele.",
						"Chyba serveru",
						0
					];
				}
				
				else if (error.response.status == 401) {
					msg = [
						"Nejste přihlášeni.",
						null,
						5000
					];
					
					this.token = "";
					let inst = this;
					
					// don't redirect if we're not showing a message
					if (!error_out) {
						setTimeout(function () {
							inst.$router.push("/login");
						}, 6000);
					}
				}
				
				else {
					msg = [
						"Server vrátil aplikaci na dotaz <em>" + error.request.responseURL +
						'</em> chybový kód <strong>' + error.response.status
						+ "</strong>. Kontaktujte provozovatele.",
						"Chyba aplikace",
						0
					];
				}
			} else {
				code = -1;
				
				msg = [
					"Nelze se spojit se serverem. Zkuste stránku obnovit a akci zopakovat.",
					null,
					0
				]
			}
			
			if (error_out) {
				error_out.code = code;
			} else if (msg.length) {
				this.$emit("notify-user", ...msg);
			}
		},
		
		/**
		 * GETs the specified method and places the result into the output
		 * array.
		 *
		 * On success, the output array is cleared and re-populated with the
		 * data returned by the API call. If available, the index is populated
		 * with gid -> object mapping of the new data.
		 *
		 * On failure, the appropriate api_call_error_xxx method is called. The
		 * output array is not affected.
		 *
		 * @param      {string}   method    The API method to call
		 * @param      {array}    output    Reference to the output array
		 * @param      {object}   index     Optional index object for gid resolving
		 * @param      {function} processor Optional callable to modify each item in-place
		 * @param      {function} on_end    Function to call when all is done
		 */
		api_call_get_array: function (method, output, index, processor, on_end) {
			API.get(method)
				.then(response => {
					console.log(`GET ${method} -> ${response.data.length} records.`);
					
					output.length = 0;
					
					response.data.forEach(function (item) {
						if (processor) {
							processor(item);
						}
						
						output.push(item);
						
						if (index) {
							index[item.gid] = item;
						}
					});
					
					if (on_end) {
						on_end();
					}
				})
				.catch(this.api_call_on_error);
		},
		
		/**
		 * GETs the specified method and places the result into the output
		 * object as its attribute: output_obj[output_key].
		 *
		 * On failure, the appropriate api_call_error_xxx method is called. The
		 * output object is not affected.
		 *
		 * @param      {string}  method      The method
		 * @param      {object}  output_obj  The output object
		 * @param      {string}  output_key  The attribute name in the output
		 *                                   object
		 * @param      {string}  single_key  Optional: single key from the response to get
		 */
		api_call_get_object: function (method, output_obj, output_key, single_key) {
			API.get(method)
				.then(response => {
					console.log(`GET ${method} ->`, response.data);
					
					output_obj[output_key] = (single_key)? response.data[single_key] : response.data;
				})
				.catch(this.api_call_on_error);
		},
		
		/**
		 * PUTs the passed object onto the specified API endpoint. The object
		 * must have a gid.
		 *
		 * On failure, the appropriate api_call_error_xxx method is called.
		 *
		 * @param      {string}    method      The method
		 * @param      {object}    obj         The object to PUT
		 * @param      {Function}  on_success  Function to call with the
		 *                                     response if successful
		 * @param      {<type>}    on_failure  dtto failure
		 */
		api_call_put_object: function (method, obj, on_success, on_failure) {
			API.put(method, obj)
				.then(response => {
					console.log(`PUT ${method} <-`, obj);
					
					if (on_success) {
						on_success(response);
					}
				})
				.catch(error => {
					if (on_failure) {
						on_failure(error);
					}
					
					this.api_call_on_error(error);
				});
		},
		
		/**
		 * POSTs the passed object onto the specified API endpoint.
		 *
		 * On failure, the appropriate api_call_error_xxx method is called.
		 *
		 * @param      {string}    method      The method
		 * @param      {object}    obj         The object to POST
		 * @param      {Function}  on_success  Function to call with the
		 *                                     response if successful
		 * @param      {<type>}    on_failure  dtto failure
		 */
		api_call_post_object: function (method, obj, on_success, on_failure) {
			API.post(method, obj)
				.then(response => {
					console.log(`POST ${method} <-`, obj);
					
					if (on_success) {
						on_success(response);
					}
				})
				.catch(error => {
					if (on_failure) {
						on_failure(error);
					}
					
					this.api_call_on_error(error);
				});
		},
		
		/**
		 * DELETEs the object identified by the URL (method) alone.
		 *
		 * On failure, the appropriate api_call_error_xxx method is called.
		 *
		 * @param      {string}    url         The URL to DELETE
		 * @param      {Function}  on_success  Function to call with the
		 *                                     response if successful
		 * @param      {Function}  on_failure  dtto failure
		 */
		api_call_delete_object: function (url, on_success, on_failure) {
			API.delete(url)
				.then(response => {
					console.log(`DELETE ${url}`);
					
					if (on_success) {
						on_success(response);
					}
				})
				.catch(error => {
					if (on_failure) {
						on_failure(error);
					}
					
					this.api_call_on_error(error);
				});
		},
		
		/**
		 * Performs an async login call on the server.
		 *
		 * On success, stores the token in localStorage.
		 *
		 * On failure, writes the HTTP code into error_out.code.
		 *
		 * @param      {string}  username   The username
		 * @param      {string}  password   The password
		 * @param      {object}  error_out  The error out
		 */
		login: function (username, password, error_out) {
			console.log("Logging in...");
			
			API.post("/auth/context", {
					"username": username,
					"password": password
				})
				.then(response => {
					console.log("logged in.");
					this.token = response.data.token;
				})
				.catch(error => {
					this.api_call_on_error(error, error_out);
				});
		},
		logout: function () {
			API.delete("/auth/context");
			this.token = "";
			
			// clean up data to ensure no artifacts remain if the user re-logins
			this.drop_global_state();
			
			this.$router.push("/login");
		},
		
		identity_get () {
			this.api_call_get_object("/auth/identity", this, "identity");
		},
		models_get () {
			this.api_call_get_array("/models", this.models, this.models_idx);
		},
		devices_get () {
			this.tags_abbr = {};
			
			let inst = this;
			
			this.api_call_get_array("/devices", this.devices, this.devices_idx, function (d) {
				let raw = d.note || "";
				let tags = raw.split("$");
				
				d.tags = {};
				d.tags[misc.protected_tag_name] = tags[0].trim();
				
				tags.slice(1).forEach((item) => {
					let sep = item.indexOf("=");
					let name = misc.capitalize(item.slice(0, sep));
					let value = item.slice(sep + 1).trim();
					
					d.tags[name] = value;
					inst.tags_abbr[name] = null;
				});
			},
			function () {
				let tags = Object.keys(inst.tags_abbr);
				let abbrs = misc.shortest_unique_prefixes(tags);
				
				for (let i = 0; i < tags.length; i++) {
					inst.tags_abbr[tags[i]] = abbrs[i];
				}
			});
		},
		orgs_get () {
			this.api_call_get_array("/orgs", this.orgs, this.orgs_idx);
		},
		users_get () {
			this.api_call_get_array("/users", this.users, this.users_idx);
		},
		refresh_global_state () {
			this.drop_global_state();
			
			this.identity_get();
			this.models_get();
			this.devices_get();
			this.orgs_get();
			this.users_get();
		},
		drop_global_state () {
			console.log("dropping state");
			
			this.identity = null;
			this.models.length = 0;
			this.models_idx = {};
			this.devices.length = 0;
			this.devices_idx = {};
			this.orgs.length = 0;
			this.orgs_idx = {};
			this.users.length = 0;
			this.users_idx = {};
		}
	},
	created () {
		// auto login
		let tok = localStorage.getItem("token");
		
		if (tok) {
			this.token = tok;
		}
	},
	watch: {
		token: function (val) {
			localStorage.setItem("token", val);
			
			if (val) {
				console.log("New token:", val);
				API.defaults.headers.common["Authorization"] = "Bearer " + val;
				window.token = val; // needed by router; I told you I'm not good at this
				this.refresh_global_state();
			} else {
				console.log("Token dropped.");
				delete API.defaults.headers.common["Authorization"];
			}
		}
	},
	computed: {
		// all data retrieved from backend
		data_loaded: function () {
			let loaded = (
				   Boolean(this.identity)
				&& this.devices.length
				&& this.orgs.length
				&& this.users.length
				&& this.models.length
			);
			
			console.log("data loaded?", loaded);
			
			return loaded;
		}
	}
});

app.$mount("#app");

window.vm = app;