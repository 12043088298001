<template>
	<v-app>
		<the-app-bar />
		
		<v-main>
			<router-view />
		</v-main>
		
		<v-snackbar :vertical="notification.vertical" v-model="notification.visible" timeout="-1" elevation="10" multi-line>
			<div v-if="notification.title" class="font-weight-bold text-uppercase mb-4">{{ notification.title }}</div>
			
			<div class="d-flex">
				<v-icon v-if="notification.icon" v-html="notification.icon" x-large left />
				<div v-html="notification.text"></div>
			</div>
			
			<template v-slot:action="{ attrs }">
				<v-btn :color="notification.color" text v-bind="attrs" @click="notification.visible = false">
					Zavřít
				</v-btn>
			</template>
			
			<v-progress-linear :active="Boolean(notification.timeout)" :value="notification.progress" :color="notification.color" absolute bottom rounded></v-progress-linear>
		</v-snackbar>
	</v-app>
</template>

<script>
import TheAppBar from "@/components/TheAppBar";

const NOTIFICATION_STEP_MS = 100;
const NOTIFICATION_TIMEOUT_MS = 10000;

export default {
	name: "App",
	components: {
		TheAppBar
	},
	data () {
		return {
			notification: {
				visible: false,
				color: "error",
				icon: null,
				vertical: false,
				title: "",
				text: "",
				progress: 0,
				timeout: NOTIFICATION_TIMEOUT_MS,
				timer: null
			}
		}
	},
	methods: {
		notification_show (text, title, timeout, color, icon) {
			this.notification.text = text;
			this.notification.title = title;
			this.notification.progress = 100;
			this.notification.vertical = Boolean(title);
			this.notification.color = color || "error";
			this.notification.icon = icon || null;
			
			if (timeout === undefined) {
				this.notification.timeout = NOTIFICATION_TIMEOUT_MS;
			} else {
				this.notification.timeout = timeout;
			}
			
			this.notification.visible = true;
			
			clearInterval(this.notification.timer);
			
			if (this.notification.timeout != 0) {
				this.notification.timer = setInterval(() => {
					if (this.notification.progress <= 0) {
						clearInterval(this.notification.timer);
						
						setTimeout(() => {
							this.notification.visible = false;
						}, 200);
					} else {
						this.notification.progress -= (100 * NOTIFICATION_STEP_MS/this.notification.timeout);
					}
				}, NOTIFICATION_STEP_MS);
			}
		},
		notification_stop () {
			clearInterval(this.notification.timer);
			this.notification.progress = 0;
		}
	},
	mounted () {
		let inst = this;
		
		this.$root.$on("notify-user", function (text, title, timeout, color, icon) {
			inst.notification_show(text, title, timeout, color, icon);
		});
	}
};
</script>

<style>
</style>
