<template>
	<v-dialog v-model="dialog" :persistent="!save_disabled" max-width="512">
		<template v-slot:activator="{ on, attrs }">
			<v-btn x-small text v-bind="attrs" v-on="on" class="float-right">
				Změnit
			</v-btn>
		</template>
		<v-card>
			<v-card-title>
				<span class="headline">Změna {{ title }}</span>
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-row>
						<v-col>
							<slot></slot>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-autocomplete
								:items="list_sorted"
								:filter="filter"
								item-text="name"
								return-object
								:label="label"
								v-model="value"
								clearable
								autofocus
								@keydown.enter="save"
							>
							</v-autocomplete>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
			<v-card-actions class="pa-4">
				<v-spacer></v-spacer>
				<v-btn text @click="dialog = false" :disabled="save_loading">Zrušit</v-btn>
				<v-btn :disabled="save_disabled || save_loading" color="primary" @click="save" :loading="save_loading">Uložit</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		obj: {
			type: Object,
			required: true
		},
		prop: {
			type: String,
			required: true
		},
		list: {
			type: Array,
			required: true
		},
		endpoint: {
			type: String,
			required: true,
			validator: function (value) {
				return value && value[0] == "/";
			}
		},
		title: {
			type: String,
			required: true
		},
		label: {
			type: String,
			required: true
		}
	},
	data: function () {
		return {
			save_loading: false,
			value_original: null,
			value: null,
			dialog_visible: false
		}
	},
	computed: {
		save_disabled: function () {
			return this.value == this.value_original;
		},
		dialog: {
			get () {
				return this.dialog_visible;
			},
			set (val) {
				this.reset();
				this.dialog_visible = val;
			}
		},
		list_sorted: function () {
			let copy = this.list.slice();
			copy.sort(function (a, b) {
				let a_name = a.name.toLowerCase();
				let b_name = b.name.toLowerCase();
				
				if (a_name < b_name) return -1;
				else if (a_name > b_name) return 1;
				else return 0;
			});
			
			return copy;
		}
	},
	methods: {
		reset: function () {
			this.save_loading = false;
			this.value_original = this.obj[this.prop];
			this.value = this.obj[this.prop];
		},
		save: function () {
			this.save_loading = true;
			this.obj[this.prop] = this.value;
			
			let inst = this;
			
			this.$root.api_call_put_object(this.endpoint, this.obj, function (response) {
				inst.save_loading = true;
				inst.dialog = false;
			},
			function () {
				inst.obj[inst.prop] = inst.value_original;
			})
		},
		filter: function (item, query_text, item_text) {
			let query_ascii = query_text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
			let item_ascii = item_text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
			
			return item_ascii.indexOf(query_ascii) > -1;
		}
	}
};
</script>
